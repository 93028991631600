/* For FireFox */
input[type="number"].numInput {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

/* For Chrome */
input[type="number"].numInput::-webkit-inner-spin-button,
input[type="number"].numInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
