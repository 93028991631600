@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800&display=swap");

/* manrope-regular */
@font-face {
  font-display: swap;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/manrope-v13-latin-regular.woff2")
      format("woff2"),
    url("../public/assets/fonts/manrope-v13-latin-regular.woff") format("woff");
}

/* manrope-500 */
@font-face {
  font-display: swap;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("../public/assets/fonts/manrope-v13-latin-500.woff2") format("woff2"),
    url("../public/assets/fonts/manrope-v13-latin-500.woff") format("woff");
}

/* manrope-600 */
@font-face {
  font-display: swap;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("../public/assets/fonts/manrope-v13-latin-600.woff2") format("woff2"),
    url("../public/assets/fonts/manrope-v13-latin-600.woff") format("woff");
}

/* manrope-700 */
@font-face {
  font-display: swap;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("../public/assets/fonts/manrope-v13-latin-700.woff2") format("woff2"),
    url("../public/assets/fonts/manrope-v13-latin-700.woff") format("woff");
}

/* manrope-800 */
@font-face {
  font-display: swap;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: url("../public/assets/fonts/manrope-v13-latin-800.woff2") format("woff2"),
    url("../public/assets/fonts/manrope-v13-latin-800.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.sonner-toast div[data-title] {
  font-size: 16px;
  font-weight: 600;
  color: #272727;
}

.sonner-toast div[data-description] {
  font-size: 14px;
  font-weight: 500;
  color: #474747;
}
